import React, { useState, useEffect } from 'react';
import {  Grid, Cell } from "components/Layout";
import { Text } from "components/Typography";
import Link, { A } from "components/Link";
import { selectIsAuthenticationCompleted, selectLogedUserId } from 'redux/selectors';
import { useSelector, useDispatch } from "react-redux";
import fetchUserData from "../../fetchUserData";

import styled from "styled-components";

const Banner = styled.div`
background: #0c263e;
  border-radius: 8px;
  padding: 22px 44px;
  color: #fbb03b;
position: relative;
overflow:hidden;
min-height: 100px;
${Link} {
    color:#eee;
}
`

const CheckoutButton = styled(Link)`
display: block;
font-family: Lato, sans-serif;
padding: 8px 32px;
margin:12px auto;
background-color:#edaf53;
border-radius: 4px;
color:#0c273e !important;
font-weight: bold;
font-size:1.3rem;
text-align:center;
`

const PriceLabel = styled(Text)`
display: inline-block;
padding: 4px 16px;
margin:12px auto;
background-color:#edaf53;
border-radius: 4px;
color:#0c273e !important;
font-weight: bold;
font-size:1.5rem;
box-shadow: 4px 4px 8px rgba(0,0,0,1);
`

const Notification = styled(Link)`
    position: absolute;
    top: 0px;
    left:50px;
    right: 50px;
    min-height:60px;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 8px;
    transform-origin: 50% 50%;
    background: #eee url(/vox-profile-logo.png) 8px 50% no-repeat;
    background-size: 32px 32px;
    box-shadow: 0 0 12px rgba(0,0,0,0.75);
    z-index:2;
    padding-left: 50px;

    @media (max-width: 920px) {
        left: 0;
        right: 0;
    }

    &.n1 {
        animation-duration: 8s;
        animation-name: n1;
        animation-iteration-count: infinite;
        z-index:1;
    }

    &.n2 {
        animation-duration: 8s;
        animation-name: n2;
        animation-iteration-count: infinite;
        z-index: 2;
    }

    @keyframes n1 {
        0% {
            translate: 0 -100px;
            scale: 0.9;
            opacity: 0.8;
        }
        10% {
            translate: 0 -100px;
            scale: 0.9;
            opacity: 0.8;
        }
        20% {
            translate: 0 0;
            scale: 1;
            opacity: 1;
        }
        45% {
            translate: 0 0;
            scale: 1;
            opacity: 1;
        }
        50% {
            translate: 0 40px;
            scale: 0.9;
            opacity: 0.8;
        }
        97% {
            translate: 0 40px;
            scale: 0.9;
            opacity: 0.8;
        }
        100% {
            translate: -100% 40px;
            scale: 0.9;
            opacity: 0.5;
        }
    }

    @keyframes n2 {
        0% {
            translate: 0 -100px;
            scale: 0.8;
            opacity: 0.8;
        }
        45% {
            translate: 0 -100px;
            scale: 0.8;
            opacity: 0.8;
        }
        55% {
            translate: 0 0;
            scale: 1;
            opacity: 1;
        }
        95% {
            translate: 0 0;
            scale: 1;
            opacity: 1;
        }
        100% {
            translate: -100% 0;
            scale: 0.9;
            opacity: 0.5;
        }
    }
`


export default function SubscriptionBanner() {

  const dispatch = useDispatch();
  const authenticationCompleted = useSelector(
    selectIsAuthenticationCompleted
  );
    const userId = useSelector(
        selectLogedUserId
    );
    const [ subscription, setSubscription ] = useState({})
    const [ userData, setUserData ] = useState(null)
    
    useEffect(async () => {
        setSubscription({pending: true});
        if(userId){
            
            const user = await fetchUserData({})
            setUserData(user);
            const check = await fetch(`https://bei289kkhe.execute-api.eu-west-1.amazonaws.com/Dev/api/*/subscriptions/subscribed`, {
                    headers: {
                    Authorization: `Bearer ${user.jwt}`
                    }
                });  
            const subscription = await check.json();
            setSubscription(subscription);
        }
    }, [userId]);

    if(!authenticationCompleted || (authenticationCompleted && !!userId) ||  (subscription && subscription.hasOwnProperty('subscriptionData'))){    
        return null;
    }
  
    return (
            <Banner>
                <Grid>
                    <Cell fill style={{position: 'relative', minHeight: '120px'}} smCol={12}>
                        <Notification className={'n2'} href="/subscribe/">
                            <Text bold>RNS Alerts</Text>
                            <Text>Subsribe for £5.49/month (ex VAT)</Text>
                        </Notification>
                        <Notification className={'n1'} href="/subscribe/">
                            <Text bold>RNS Alerts</Text>
                            <Text lato as="p">Get real-time RNS Push Notifications in Vox Markets mobile app</Text>
                        </Notification>
                    </Cell>
                    <Cell fit  smCol={12}>
                        <CheckoutButton href="/subscribe/">Subscribe Now</CheckoutButton>
                        
                        <Text center>
                            <Link href="/app/">Get Vox Markets app</Link>
                        </Text>
                    </Cell>
                </Grid>
            </Banner>
        );

}
