import React from "react";
import { useCategoryArticles, useCategories } from "features/articles/queries";

import { Grid, Cell, Spacing } from "components/Layout";
import Text from "components/Text";
import Link from "components/Link";

import Article from "features/articles/components/Article";

const ArticleBlock = ({ category, label, ...props }) => {
    const { data: categories } = useCategories();
    const { data: articles } = useCategoryArticles( category, categories );
    const slugMap = categories ? categories.reduce( (acc, cur) => {
        if(cur.hasOwnProperty("slug")){
          return { [cur.value] : cur.slug, ...acc }
        }
        return acc;
      }, {}) : {}
    const categorySlug = slugMap.hasOwnProperty(category) ? slugMap[category] : null;

  return <Spacing all={1}>
    <Text as="h2" bold size={1.2}><Link href={`/all-articles/${category}/`}>{label}</Link></Text>
    <Spacing top={1}>
      <Grid>
        {articles && articles.pages[0].slice(0,4).map( a => <Cell  mdCol={6} lgCol={3}><Spacing bottom={0.5}><Article article={a} /></Spacing></Cell>)}
      </Grid>
    </Spacing>
  </Spacing>
}

export default ArticleBlock;
