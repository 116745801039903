import React from "react";
import PageMeta from "components/PageMeta";
import { loggedIn } from "../../redux/selectors";
import { atmedia } from "../../components/VoxTheme";
import styled from "styled-components";
import { Spacing } from "../../components/Layout";
import { MostLikedNews, MostFollowed } from "features/reports";
import FeaturedBlogs from "./FeaturedBlogs";
import ArticleBlock from "./ArticleBlock";
import CollectionBox from "./CollectionBox";
import SeriesBox from "./SeriesBox";

import GCI from "./GCI";
import ThemeContext from "../../components/ThemeContext";
import { BoxShadow2 } from "../../components/Utils";
import { Text } from "../../components/Typography";
import Button from "../../components/Button";
import MarketMovers from "../../components/MarketMovers";
import MainBanner from "features/banners/components/MainBanner";
import SubscriptionsBanner from "pages/subscription/banner"




const PageLayout = styled.main`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "content content"
  "rightBar rightBar"
  "timeline timeline"
  "leftBar leftBar"
  "midPanel midPanel"
  "mediaBar mediaBar";
  ${atmedia.desktop`
grid-template-columns: 1fr 1fr 1fr; 
grid-template-areas:
                   "content content content"
                   "leftBar midPanel rightBar"
                   "mediaBar mediaBar mediaBar";
    max-width: 100%;
`}`;

const Content = styled.div`
  grid-area: content;  
  ${BoxShadow2} + ${BoxShadow2} {
  margin-top:2rem;
  }

`;

const RightBar = styled(BoxShadow2)`
  grid-area: rightBar;
`;

const LeftBar = styled(BoxShadow2)`
  grid-area: leftBar;
`;

  const MidPanel = styled(BoxShadow2)`
  grid-area: midPanel;
  `;


const PartnerContent = styled.section`
grid-area: mediaBar;
  `;

const Banner = styled(MainBanner)`
  background-color: #1b1b1b;
  width: 100%;
  max-width: 100%;
  height: 200px;
  overflow: hidden;
  margin: 0 auto 1em;
  > * {
    display:block;
    position: relative;
    width: 100%;
  }
  img {
    width: 1740px;
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -870px
  }
`;


export default function Home() {  
  return (
    <>
      <PageMeta description="Vox Markets is revolutionising the way companies engage with shareholders and the stock market at large." />
      <Spacing bottom={2}>
        <SubscriptionsBanner />
      </Spacing>
      <PageLayout>
        <Content as="section">
          <BoxShadow2>
            <Spacing horizontal={1} top={1}>
              <FeaturedBlogs  />
            </Spacing>
    </BoxShadow2>
    {/*
    <BoxShadow2>
      <ThemeContext.Provider value="GCI">
      <CollectionBox collectionId="663b34c4c3a6e4e9682027c8">
        <GCI />
      </CollectionBox>
      </ThemeContext.Provider>
        </BoxShadow2>
        */}


        <BoxShadow2>
          <SeriesBox category="mining-matters" label="Mining Matters" />
        </BoxShadow2>

        <BoxShadow2>
          <SeriesBox category="fund-manager-q-and-a" label="The Exchange" />
        </BoxShadow2>

        <BoxShadow2>
          <SeriesBox category="small-cap-investing" label="Small-cap Investing" />
        </BoxShadow2>

        <BoxShadow2>
          <SeriesBox category="q-and-a" label="Company Q&A" />
        </BoxShadow2>




      
    </Content>

    <MidPanel>
      <MostLikedNews />
    </MidPanel>
    
    <RightBar>
      <MarketMovers />
    </RightBar>
    
    <LeftBar>
      <Spacing bottom={1}>
        <MostFollowed />
      </Spacing>
    </LeftBar>


    <PartnerContent>
        <BoxShadow2>
        {/*<ArticleBlock category={["Company Broker Research"]} label="Broker Research" />*/}
        </BoxShadow2>
    </PartnerContent>
    
    </PageLayout>
    </>
  );
}
